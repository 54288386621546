import { Provider } from 'react-redux'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import EstiloGlobal, { Container } from './styles'

import store from './store'
import Home from './pages/Home'
import InclusaoTarefa from './pages/Cadastro'

const rotas = createBrowserRouter([
  { path: '/', element: <Home /> },
  { path: '/novo', element: <InclusaoTarefa /> }
])

function App() {
  return (
    <Provider store={store}>
      <EstiloGlobal />
      <Container>
        <RouterProvider router={rotas} />
      </Container>
    </Provider>
  )
}

export default App
